import React from 'react'
import Helmet from 'react-helmet'
import ConditionalLayout from '../components/ConditionalLayout'
import ServiceContainer from '../components/ServiceContainer'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import config from '../utils/siteConfig'
import QuickApply from '../components/QuickApply'
import {
  LargeP,
  FlexList,
  FlexItem,
  InlineLink,
  DownloadButton,
} from '../elements'

const CompanyDriversPage = ({ data }) => {
  const postNode = {
    title: `Company Drivers - ${config.siteTitle}`,
  }

  return (
    <ConditionalLayout>
      <Helmet>
        <title>{`Company Drivers - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="contact" customTitle />

      <ServiceContainer>
        <PageTitle>Quick Apply</PageTitle>
        {/* <LargeP>
          Transportation is the backbone of our nation and the lifeline that
          delivers vital commodities around the world.
        </LargeP> */}
        <p>
          Fill out our Quick Apply form below or call{' '}
          <InlineLink href="tel:+18002237671" rel="nofollow">
            (800) 223-7671
          </InlineLink>{' '}
          to have a recruiter contact you. Or if you are ready, fill out our{' '}
          <InlineLink
            rel="noopener noreferrer"
            href="https://intelliapp.driverapponline.com/c/ddtsi"
            target="_blank"
          >
            Driver Application
          </InlineLink>
          .
        </p>
        <QuickApply />
      </ServiceContainer>
    </ConditionalLayout>
  )
}

export default CompanyDriversPage
